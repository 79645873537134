import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Presentation = () => (
  <Layout>
    <SEO title="Ζητήστε παρουσίαση" />
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScREchj_j5iSjTo7VCBntBWkOrHWuWIDZnWasUP5fn7HNfm3A/viewform?embedded=true" width="560" height="1200" frameBorder="0" marginHeight="0" marginWidth="0" title="Υποβολή αίτησης για παρουσίαση">Φόρτωση…</iframe>
  </Layout>
)

export default Presentation
